
// メタデータ
export const bustArr= ["未設定", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
export const rootUri = "https://m-marineblue.com"
export const apiRoot = "https://m-marineblue.com/api"
export const diaryApiRoot = "https://api.marineblue-g.com/api"
export const hvnUriRoot = "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue"

// ヘブンブログパーツ類
export const hvnShopId = "2510019691";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=8&fontcolor=10&btncolor=6&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "マリン水戸店",
    tel:        "029-306-7882",
    openTime:   "6:00~24:00",
    zipCode:    "310-0025",
    zipAddress: "茨城県 水戸市 天王町6-20",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.281557679869!2d140.45784531498035!3d36.37817748003762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60222445c78f0c8d%3A0xcafba2be7c79ec73!2z44CSMzEwLTAwMjUg6Iyo5Z-O55yM5rC05oi45biC5aSp546L55S677yW4oiS77yS77yQ!5e0!3m2!1sja!2sjp!4v1651196325624!5m2!1sja!2sjp",
};

export const priceChart = "/img/price_20241227.webp"
export const priceChart0106 = "/img/price_20241214.webp"

// 20240517 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart0106

    }
    return priceChart
}

export const areaName = "茨城"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人一覧",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍一覧",        path: "/allcasts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "ランキング",      path: "/ranking", blank: false, img: "/img/ico/i-ranking.png"},
        {name: "料金システム",     path: "/system", blank: false, img: "/img/ico/i-system.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-access.png"},
//        {name: "内装紹介", path: "/interior", blank: true, img: "/img/ico/i-interior.png"},
        {name: "写メ日記", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
        {name: "動画一覧", path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-movie.png"},
        {name: "ネット予約", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
        {name: "口コミ", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
        {name: "男子求人", path: "https://mens-qzin.jp/ibaraki/area_08001/detail/marinebluemito/?v=official", blank: true, img: "/img/ico/i-rec-men.png"},
        {name: "女子求人", path: "https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/mt-marin-blue/?of=y", blank: true, img: "/img/ico/i-rec-women.png"},
];


export const slideArr = [

    {
        link: {
            blank: true,
            uri: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/attend/?of=y" },
        img: {
            alt: "",
            pc: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue",
            sp: "https://api.marineblue-g.com/api/topbanner?t=m_marineblue&sp=t",
        },
    },

    {
        link: {
            blank: true,
            uri: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/shopevent/?of=y" },
        img: {
            alt: "",
            pc: "/img/slides/20241231/s02-pc.jpg",
            sp: "/img/slides/20241231/s02-sp.jpg",
        },
    },

    {
        link: {
            blank: true,
            uri: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/freespace-2/?of=y" },
        img: {
            alt: "",
            pc: "/img/slides/20241231/s03-pc.jpg",
            sp: "/img/slides/20241231/s03-sp.jpg",
        },
    },
    {
        link: {
            blank: true,
            uri: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/freespace-3/?of=y" },
        img: {
            alt: "",
            pc: "/img/slides/20241231/s04-pc.jpg",
            sp: "/img/slides/20241231/s04-sp.jpg",
        },
    },

];

export const castId = "useParams";


export const ranking=[
    {id: 184, rank: 1},
    {id: 338, rank: 2},
    {id: 487, rank: 3},
    {id: 559, rank: 4},
    {id: 517, rank: 5},
]

export const newfaceRanking=[
    {id: 554, rank: 1},
    {id: 558, rank: 2},
    {id: 552, rank: 3},
    {id: 543, rank: 4},
    {id: 553, rank: 5},
]

/*
export const castRanking = {
        rank1st: "184" ,//1-せいか
        rank2nd: "487" ,//2-なみ
        rank3rd: "338" ,//3-しおん
        rank4th: "361" ,//4-みすず
        rank5thA: "163" ,//5-みおな
        rank5thB: "322" ,//5-ちはる
};

export const newfaceRanking = {
    NewFace1st: "517" ,//1-ミク
    NewFace2nd: "513" ,//2-エミリ
    NewFace3rd: "510" ,//3-こいと
    NewFace4th: "525" ,//4-まや
    NewFace5thA: "519" ,//5-あや
    NewFace5thB: "518" ,//5-ゆきな
};

export const newfaceRanking = {
    NewFace1st: "519" ,//1-あや
    NewFace2nd: "523" ,//2-てぃな
    NewFace3rd: "529" ,//3-ひめか
    NewFace4th: "530" ,//4-ひつじ
    NewFace5th: "526" ,//5-なぎ

    NewFace1st: "554" ,//1-さきな
    NewFace2nd: "558" ,//2-あん
    NewFace3rd: "552" ,//3-るみ
    NewFace4th: "543" ,//4-えりか
    NewFace5th: "553" ,//5-みな
};
*/